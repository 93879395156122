import React from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Slider.css';

const Slider = () => {
    return (
        <div className="carousel">
        <Carousel fade={true} pause={false} indicators={false}>
            <Carousel.Item interval={3500}>
                <img
                    className="d-block w-100"
                    src='img/inicio/SliderIni1.jpg'
                    alt="Roedjot lideres en Servicios Profesionales de Fumigación"
                />
                <Carousel.Caption>
                    <div className="titulo">
                        <h2>SANEAMIENTO ROEDJOT S.A.C</h2>
                        <p>Líderes en servicios profesionales de fumigación</p>
                        <h5>LLámanos 973667643/988833612</h5>
                    </div>
                </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item interval={3500}>
                <img
                    className="d-block w-100"
                    src='img/inicio/SliderIni2.jpg'
                    alt="Roedjot lideres en Servicios Profesionales de Fumigación"
                />
                <Carousel.Caption>
                    <div className="titulo">
                        <h2>Servicio de Desinsectación</h2>
                        <a href="./#/servicios/desinsectacion">Ver Más</a>  
                    </div>
                </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item interval={3500}>
                <img
                    className="d-block w-100"
                    src='img/inicio/SliderIni3.jpg'
                    alt="Roedjot lideres en Servicios Profesionales de Fumigación"
                />
                <Carousel.Caption>
                    <div className="titulo">
                        <h2>Servicio de Desratización</h2>
                        <a href="./#/servicios/desratizacion">Ver Más</a>
                    </div>
                </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item interval={3500}>
                <img
                    className="d-block w-100"
                    src='img/inicio/SliderIni4.jpg'
                    alt="Roedjot lideres en Servicios Profesionales de Fumigación"
                />
                <Carousel.Caption>
                    <div className="titulo">
                        <h2>Servicio de Desinfección</h2>
                        <a href="./#/servicios/desinfeccion">Ver Más</a>
                    </div>
                </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item interval={3500}>
                <img
                    className="d-block w-100"
                    src='img/inicio/SliderIni5.jpg'
                    alt="Roedjot lideres en Servicios Profesionales de Fumigación"
                />
                <Carousel.Caption>
                    <div className="titulo">
                        <h2>Limpieza de Ambientes</h2>
                        <a href="./#/servicios/limpiezaAreas">Ver Más</a>
                    </div>
                </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item interval={3500}>
                <img
                    className="d-block w-100"
                    src='img/inicio/SliderIni6.jpg'
                    alt="Roedjot lideres en Servicios Profesionales de Fumigación"
                />
                <Carousel.Caption>
                    <div className="titulo">
                        <h2>Limpieza y Desinfeccion Reservorios de Agua</h2>
                        <a href="./#/servicios/manttoReservorios">Ver Más</a>
                    </div>
                </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item interval={3500}>
                <img
                    className="d-block w-100"
                    src='img/inicio/SliderIni7.jpg'
                    alt="Roedjot lideres en Servicios Profesionales de Fumigación"
                />
                <Carousel.Caption>
                    <div className="titulo">
                        <h2>Control de Aves</h2>
                        <a href="./#/servicios/manttoReservorios">Ver Más</a>
                    </div>
                </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item interval={3500}>
                <img
                    className="d-block w-100"
                    src='img/inicio/SliderIni8.jpg'
                    alt="Roedjot lideres en Servicios Profesionales de Fumigación"
                />
                <Carousel.Caption>
                    <div className="titulo">
                        <h2>Limpieza de Campana Extractora</h2>
                        <a href="./#/servicios/controlAves">Ver Más</a>
                    </div>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
        </div>
)
}

export default Slider;