import React, { Component } from 'react';
import Navbar from '../common/navbarComponent/Navbar';
import Footer from '../common/footerComponent/Footer';
import WhatsAppWidget from 'react-whatsapp-widget';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { Card } from 'react-bootstrap';
import PopUpComponent from '../common/popUpContacComponent/PopUpComponent';
import GalleriaComponent from '../common/galleriaComponent/GalleriaComponent';
import { GalleriaItems_desinsectacion } from '../common/galleriaComponent/Galerias';

import './desinsectacion.css';

//import video1 from './media/desinsectacion-roedjot.mp4';

class DesinsectacionViewPresentation extends Component{
    render(){
        return(
            <div>
                <Navbar />
                {/*
                    <div className="video__servicio">
                        <video autoplay controls>
                            <source src={video1} type="video/mp4"></source>
                        </video>
                    </div>
                */}
                <div>
                    <img className="banner_servicio" src ='img/desinsectacion/desinsectacion-roedjot.jpg' alt="Servicios de desinsectacion en lima"></img>
                </div>
                        <div className="tittle animate__animated animate__slideInUp">
                            <h2>DESINSECTACIÓN Y <span>FUMIGACIÓN</span></h2>
                        </div>
                        <Card className="desinsectacion__info1 animate__animated animate__slideInUp">
                            <Card.Img  className="desinsectacion__info1--imagen" variant="top" src='img/desinsectacion/desinsectacion1.jpg' alt='img-desinsectacion' title="Servicio de Desinsectacion"/>
                            <Card.Body className="desinsectacion__info1--cuerpo">
                                <Card.Title className="mb-2 text-muted">Contamos con técnicos calificados con más de 30 años de experiencia en salud pública</Card.Title>
                                <Card.Text className="desinsectacion__info1--cuerpo--texto">
                                <p>La <b>DESINSECTACIÓN</b> es la acción de controlar determinados antródopos dañinos, principalmente <b>insectos</b>, a través de un conjunto de técnicas y métodos físicos, químicos y mecánicos o con la aplicación de medidas de <b>saneamiento básico</b> dirigidos a prevenir y controlar la presencia de ciertas <b>especies de artrópodos</b> nocivos y molestos en un hábitad determinado. Estas especies son <b>dañinas para la agricultura</b>, ganadería, para las personas, etc.</p>
                                </Card.Text>
                            </Card.Body>
                        </Card>

                        <Card className="desinsectacion__info2 animate__animated animate__slideInUp">
                            <Card.Body className="desinsectacion__info2--cuerpo">
                                <Card.Title className="mb-2 text-muted">Los procedimientos de control se llevarán a cabo contra:</Card.Title>
                                <Card.Text className="desinsectacion__info2--cuerpo--texto">
                                    <ul>
                                        <li>Vectores Voladores</li>
                                        <li>Vectores Caminadores</li>
                                        <li>Antrópodos Hematófagos</li>
                                        <li>Antrópodos Plagas Molestas</li>
                                        <li>Antrópodos de los productos almacenados</li>
                                    </ul>
                                    <p>La mayoría de estas especies son insectos, aunque también hay entre estas plagas arácnidos y crustáceos</p>
                                </Card.Text>
                            </Card.Body>

                            <Card.Body className="desinsectacion__info2--cuerpo">
                                <Card.Title className="mb-2 text-muted">Los insectos más comunes considerados como de interés en la salud pública y dentro de las actividades económicas son:</Card.Title>
                                <Card.Text className="desinsectacion__info2--cuerpo--texto">
                                    <ul>
                                        <li>Cucarachas (diversos tipos)</li>
                                        <li>Hormigas</li>
                                        <li>Avispas</li>
                                        <li>Pulga</li>
                                        <li>Chinche de las camas</li>
                                        <li>Arañas</li>
                                        <li>Picudo Rojo</li>
                                        <li>Procesionaria</li>
                                        <li>Mosca Doméstica</li>
                                        <li>Mosquito</li>
                                        <li>Alúas</li>
                                    </ul>
                                </Card.Text>
                            </Card.Body>
                        </Card>                     
                        
                        <Card className="desinsectacion__info3">
                            <Card.Body className="desinsectacion__info3--cuerpo">
                                <Card.Title className="mb-2 text-muted">PROCESO DEL SERVICIO DE FUMIGACIÓN</Card.Title>
                                <Card.Text className="desinsectacion__info3--cuerpo--texto">
                                <p>Para realizar los servicios de fumigación presentamos los trabajaos previos, control físico, preparación de ambientes, procedimiento químico, así como actividades pos tratamiento que deben realizar las personas afectadas con el problema de roedores y la empresa de fumigación.</p>

                                <p>El objetivo principal de cualquier tratamiento de desinsectacion se puede expresar como: mantener la población de antrópodos dentro del umbral de tolerancia según el área de que se trate.</p>

                                <p>Nuestro ámbito de actuaciónen rangon generales es el ambiental, dirigido a espacios abiertos, locales o instalaciones de uso público o privado, medios de transporte o viviendas, y el de la industria alimentaria, es decir, en espacios, locales, instalaciones o equipos relacionados con produccíon, transporte, almacenamiento o consumo de alimentos.</p>
                                </Card.Text>

                                <Card.Title className="mb-2 text-muted">PROTOCOLO DE ACTUACIÓN ANUAL</Card.Title>
                                <Card.Text className="desinsectacion__info3--cuerpo--texto">
                                <ul>
                                    <li>Inspección de las instalaciones para la identificación de las especies a combatir.</li>
                                    <li>Estimación de la densidad de la población</li>
                                    <li>Estudio del posible origen de las citadas especies, así como distribución y extensión de las poblaciones que pueden afectar a las instalaciones a tratar</li>
                                    <li>Estudio de los factores ambientales que originen o favorezcan la poliferación de la plaga.</li>
                                    <li>Diagnóstico de la situación, planteando propuestas de actuaciones físicas, químicas y/o biologicas.</li>
                                    <li>Aconsejaremos al cliente, según el diagnóstico del problema, sobre la periodicidad del tratamiento.</li>
                                    <li>Tratamientos físicos,, químicos y/o biológicos.</li>
                                    <li>Plan de control anual</li>
                                    <li>Elaboración de plan general de higiene, si las circunstancias y caracteristicas de la empresa lo requiere.</li>
                                    <li>Seguimiento y recomendaciones </li>
                                </ul>
                                </Card.Text>
                            </Card.Body>
                            <div className="images__desinsectacion">
                                <Card.Img  className="desinsectacion__info3--imagen" variant="top" src='img/desinsectacion/desinsectacion2.jpg' />
                                <Card.Img  className="desinsectacion__info3--imagen" variant="top" src='img/desinsectacion/desinsectacion3.jpg' />
                            </div>
                        </Card> 
                <PopUpComponent/>
                <GalleriaComponent body={GalleriaItems_desinsectacion}  />      
                <Footer/> 
                <div className="widget-fb">
                    <MessengerCustomerChat
                    pageId="1853691534879265"
                    appId="293907205561035"
                    language= 'es_ES'
                    greeting_dialog_display ="fade"
                    greeting_dialog_delay = "2"
                />
                </div>

                <div className="widget-wsp">
                    <WhatsAppWidget 
                    phoneNumber='51973667643' 
                    companyName='Roedjot S.A.C' 
                    textReplyTime='Tipicamente responde a 2 minutos' 
                    message='¡Hola! 👋🏼 ¿Qué podemos hacer por usted?' 
                    sendButton='Enviar' 
                    placeholder='Escribe tu mensaje' 
                    />
                </div>
            </div>
        )
    }
}
export default DesinsectacionViewPresentation;