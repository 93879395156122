import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
import App from './app/App';

import * as serviceWorker from "./serviceWorker";
import ScrollToTop from "./ScrollToTop";
import { HashRouter } from "react-router-dom";

ReactDOM.render(
      <HashRouter >
        <ScrollToTop>
          <App />
        </ScrollToTop>
      </HashRouter>,
  document.getElementById("root")
);

