import React, { Component } from 'react';
import { WithRouter } from 'react-router-dom';
import { ContactoViewPresentation } from '../contactoComponent';

class ContactoViewContainer extends Component {
    render(){
        return(
            <div className={this.props.isLoading ? "card card-width-opacity" : "card"}>
                <ContactoViewPresentation />
            </div>
        );
    }
}

export default ContactoViewContainer;