import React , { Component } from 'react';
import { Card } from 'react-bootstrap';
import 'primeflex/primeflex.css';
import './Objetivos.css';


class Objetivos extends Component {
    render(){
        return(
            <div>
                <Card className="header">
                    <Card.Body className="header__body">
                        <Card.Title className="mb-2 text">Nuestros Objetivos</Card.Title>
                        <Card.Text className="header__description">
                            Llevar a cabo un servicio de manejo integrado de plagas, limpieza y asesoria sanitaria que permita preservar la salud e higiene de nuestros clientes, mediante labores concretas, siempre tomando medidas que respeten el medio ambiente y la salud de las personas.
                        </Card.Text>
                    </Card.Body>
                </Card>
                            
                <Card className="info__objetivos">
                    <Card.Body className="info__objetivos--uno">
                        <Card.Title className="mb-2 text">Objetivos Específicos</Card.Title>
                        <Card.Text className="header__description">
                            <ul>
                                <li>Establecer las medidas de control, corrección y prevención que reduzcan los riesgos de infestación de plagas, mediante la formulación y diseño de planes de saneamiento ambiental integrado.</li>
                                <li>Prevenir la transmisión de enfermedades, otras afecciones y molestias asociadas a plagas y patógenos.</li>
                                <li>Contribuir al cumplimiento de normas de seguridad industrial y salud ocupacional, previniendo riesgos para la salud mejorando las condiciones de trabajo y logrando una mejor calidad de vida.</li>
                                <li>Evitar daños a mercancías, estructuras, contaminaciones de alimentos, ambientes o perdidas de prestigio e imagen, provocados por efecto de plagas y patógenos. </li>
                            </ul>
                        </Card.Text>
                    </Card.Body>
                                
                    <Card.Body className="info__objetivos--uno">
                        <Card.Title className="mb-2 text">Objetivos de Calidad</Card.Title>
                        <Card.Text className="header__description">
                            <ul>
                                <li>Proporcionar calidad = Solución de problemas de nuestros clientes en cada trabajo realizado.</li>
                                <li>Incrementar la confianza de las empresas y clientes sobre nuestro servicio.</li>
                                <li>Concientizar a las empresas para lograr la prevención a través de un manejo integrado de plagas.</li>
                                <li>Reducir la presencia de plagas en las instalaciones de nuestros clientes. </li>
                                <li>Dar una respuesta amable y rápida al cliente ya que su trabajo es lo más importante para nosotros.</li>
                                <li>Mantener una mejora continua en el desarrollo y capacitación de nuestro personal y clientes, reflejando un servicio de calidad y el cuidado del medio ambiente</li>
                            </ul>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>
        )
    }
}

export default Objetivos;
