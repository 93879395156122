
export const CarouselItems = [
    {
        id: 1, 
        title: 'AERCARIBE SERVICE S.A.C',
        image: 'img/inicio/carousel-cli1.jpg'
    },
    {
        id: 2, 
        title: 'CONSORCIO AERONAVAL CALLAO',
        image: 'img/inicio/carousel-cli2.jpg'
    },
    {
        id: 3, 
        title: 'AGROPSA', 
        image: 'img/inicio/carousel-cli3.jpg'
    },
    {
        id: 4, 
        title: 'ALICORP',
        image: 'img/inicio/carousel-cli4.jpg'
    },
    {
        id: 5, 
        title: 'AUTONIZA',
        image: 'img/inicio/carousel-cli5.jpg'
    },

    {
        id: 6, 
        title: 'MUNICIPALIDAD DE CARABAYLLO',
        image: 'img/inicio/carousel-cli6.jpg'
    },

    {
        id: 7, 
        title: 'CENTRAL PERÚ',
        image: 'img/inicio/carousel-cli7.jpg'    
    },

    {
        id: 8, 
        title: 'CONCYTEC',
        image: 'img/inicio/carousel-cli8.jpg'    
    },

    {
        id: 9, 
        title: 'COCO', 
        image: 'img/inicio/carousel-cli9.jpg'    
    },

    {
        id: 10, 
        title: 'VEGA',
        image: 'img/inicio/carousel-cli10.jpg'
    },

    {
        id: 11, 
        title: 'CSBEAVER',
        image: 'img/inicio/carousel-cli11.jpg'
    },

    {
        id: 12, 
        title: 'CONSORCIO SANEAMIENTO CAJAMARQUILLA',
        image: 'img/inicio/carousel-cli12.jpg'
    },

    {
        id: 13, 
        title: 'PODER JUDICIAL DEL PERÚ',
        image: 'img/inicio/carousel-cli13.jpg'
    },

    {
        id: 14, 
        title: 'FAGA MOTORS',
        image: 'img/inicio/carousel-cli14.jpg'
    },

    {
        id: 15, 
        title: 'GCZ INGENIEROS S.A.C', 
        image: 'img/inicio/carousel-cli15.jpg'
    },

    {
        id: 16, 
        title: 'MIMP',
        image: 'img/inicio/carousel-cli16.jpg'
    },

    {
        id: 17, 
        title: 'IVC CONTRATISTAS',
        image: 'img/inicio/carousel-cli17.jpg'
    },

    {
        id: 18, 
        title: 'LA OLLA AREQUIPEÑA',
        image: 'img/inicio/carousel-cli18.jpg'
    },

    {
        id: 19, 
        title: 'LADRILLOS ARCIMAX',
        image: 'img/inicio/carousel-cli19.jpg'
    },

    {
        id: 20, 
        title: 'LAFARMED',
        image: 'img/inicio/carousel-cli20.jpg'
    },

    {
        id: 21, 
        title: 'PLANSA', 
        image: 'img/inicio/carousel-cli21.jpg'
    },

    {
        id: 22, 
        title: 'LIMA VÍAS EXPRESS S.A.',
        image: 'img/inicio/carousel-cli22.jpg'
    },

    {
        id: 23, 
        title: 'LIMA VÍAS EXPRESS S.A.',
        image: 'img/inicio/carousel-cli23.jpg'
    },

    {
        id: 24, 
        title: 'MR. SUSHI',
        image: 'img/inicio/carousel-cli24.jpg'
    },

    {
        id: 25, 
        title: 'MULTITOP',
        image: 'img/inicio/carousel-cli25.jpg'
    },

    {
        id: 26, 
        title: 'MAGDALENA DEL MAR',
        image: 'img/inicio/carousel-cli26.jpg'
    },

    {
        id: 27, 
        title: 'PERÚ COMPRAS', 
        image: 'img/inicio/carousel-cli27.jpg'
    },

    {
        id: 28, 
        title: 'CARTAVIO RUM COMPANY',
        image: 'img/inicio/carousel-cli28.jpg'
    },
    
    {
        id: 29, 
        title: 'SENAMHI',
        image: 'img/inicio/carousel-cli29.jpg'
    },

    {
        id: 30, 
        title: 'WONG & CIA S.A TECNOLOGÍA DE FLUIDOS',
        image: 'img/inicio/carousel-cli30.jpg'
    }
]