import React, { Component } from 'react';
import { WithRouter } from 'react-router-dom';
import { ControlAvesViewPresentation } from '../controlAvesComponent';

class ControlAvesViewContainer extends Component {
    render(){
        return(
            <div className={this.props.isLoading ? "card card-with-opacity" : "card"}>
                <ControlAvesViewPresentation />
            </div>
        )
    }
}
export default ControlAvesViewContainer;