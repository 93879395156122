import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { ManttoCampanaViewPresentation } from '../manttoCampanaComponent';

class ManttoCampanaViewComponent extends Component {
    render(){
        return(
            <div className={this.props.isLoading ? "card card-with-opacity" : "card"}>
            <ManttoCampanaViewPresentation />
            </div>
        )
    }
}
export default ManttoCampanaViewComponent;
