import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import "./Info.css";

import brochure from './document/BROCHURE-2021.pdf';
class Info extends Component {

    render() {
        return (
            <Card className="abc">
                <Card.Img  className="card__image" variant="top" src='img/inicio/infoCollage.jpg' />
                <Card.Body className="card__body">
                    {/*<Card.Title>Nuestra Compañia</Card.Title>
                    <Card.Subtitle></Card.Subtitle>*/}
                    <Card.Title className="mb-2 text-muted">Contamos con más de 30 años de experiencia en salud pública</Card.Title>
                    <Card.Text className="card__description">Somos una empresa líder, dedicada a servicios profesionales de fumigación, desinfección, desratización y mantenimiento en general, reconocida por su seriedad, compromiso y calidad de sus servicios. Contamos con el respaldo legal documentado según resolución administrativa del MINSA expedida por la dirección ejecutiva de Salud Ambiental.</Card.Text>
                    <br/><br/>
                    <a className="link__brochure" href={brochure} download="BROCHURE2021-ROEDJOT">Descargar Brochure</a>
                </Card.Body>
            </Card>
        )
    }
}

export default Info;