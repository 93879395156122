import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { DesinfeccionViewPresentation } from '../desinfeccionComponent';

class DesinfeccionViewContainer extends Component {
    render(){
        return(
            <div className={this.props.isLoading ? "card card-with-opacity" : "card"}>
            <DesinfeccionViewPresentation />
            </div>
        )
    }
}
export default DesinfeccionViewContainer;
