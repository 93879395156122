import React, { Component } from 'react';
import Navbar from '../common/navbarComponent/Navbar';
import Footer from '../common/footerComponent/Footer';
import WhatsAppWidget from 'react-whatsapp-widget';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import ContactoComponent from '../common/contactoComponent/ContactoComponent';

import 'react-whatsapp-widget/dist/index.css';

import './contacto.css';
class ContactoViewPresentation extends Component{
    
    render(){
        return(
            <div>
                <Navbar />
                    <div className="formulario">
                    <ContactoComponent />
                    </div>
                <Footer />
                <div className="widget-fb">
                    <MessengerCustomerChat
                    pageId="1853691534879265"
                    appId="293907205561035"
                    language= 'es_ES'
                    greeting_dialog_display ="fade"
                    greeting_dialog_delay = "1"
                />
                </div>

                <div className="widget-wsp">
                    <WhatsAppWidget 
                    phoneNumber='51973667643' 
                    companyName='Roedjot S.A.C' 
                    textReplyTime='Tipicamente responde a 2 minutos' 
                    message='¡Hola! 👋🏼 ¿Qué podemos hacer por usted?' 
                    sendButton='Enviar' 
                    placeholder='Escribe tu mensaje' 
                    />
                </div>
            </div>        
        )
    }
}
export default ContactoViewPresentation