//import ReactDOM from "react-dom";
import React, { Component, Fragment } from "react";
import { Carousel } from "primereact/carousel";
import "./Carousel.css";
import {CarouselItems} from './CarouselItems';


class Carousell extends Component {

constructor(props) {
    super(props);

    this.responsiveOptions = [
        {
            breakpoint: "1024px",
            numVisible: 3,
            numScroll: 3
        },
        {
            breakpoint: "600px",
            numVisible: 2,
            numScroll: 2
        },
        {
            breakpoint: "480px",
            numVisible: 1,
            numScroll: 1
        }
    ];
    }

    clientTemplate(client) {
        return (
            <div className="product-item">
                <div className="product-item-content">
                    <div className="p-mb-3">
                        <img src={client.image} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={client.title} className="product-image" />
                    </div>
                </div>
            </div>
        );
    }

    
    render() {
    return (  
    <div className="carousel-demo">
        <div className="card">
            <Carousel value={CarouselItems} numVisible={4} numScroll={1} responsiveOptions={this.responsiveOptions} className="" circular autoplayInterval={3000} itemTemplate={this.clientTemplate}/>
        </div>
    </div>
    );
    }
}

export default Carousell;