import React, { Component } from 'react';
import Navbar  from '../common/navbarComponent/Navbar';
import Footer from '../common/footerComponent/Footer';
import WhatsAppWidget from 'react-whatsapp-widget';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { Card } from 'react-bootstrap';
import PopUpComponent from '../common/popUpContacComponent/PopUpComponent';
import GalleriaComponent from '../common/galleriaComponent/GalleriaComponent';
import {GalleriaItems_desratizacion} from '../common/galleriaComponent/Galerias';

import './desratizacion.css';

class DesratizacionViewPresentation extends Component{
    render(){
        return(
            <div>
                <Navbar />
                {/* 
                <div className="video__servicio">
                    <video autoplay controls>
                        <source src={video1} type="video/mp4"></source>
                    </video>
                </div>
                */}
                <div>
                    <img className="banner_servicio" src='img/desratizacion/desratizacion-roedjot.jpg' alt="Servicio de desratizacion en Lima"></img>
                </div>
                <div className="tittle animate__animated animate__slideInUp">
                    <h2>SERVICIO DE <span>DESRATIZACION</span></h2>
                </div>

                <Card className="desratizacion animate__animated animate__slideInUp">
                            <Card.Body className="desratizacion__info1">
                                <Card.Text className="desratizacion__info1--texto">
                                    <p>El servicio de desratización debe de ser una tarea permanente, ya que los roedores son causantes de más del 30% de incendios desconocidos, ya que tratan de roer todo cuanto encuentran a su alrededor y con sus pelos heces y orina contaminan todo cuanto tocan, motivo por el cual no hay que esperar tener un problema de roedores para comenzar a desratizar. Si preventivamente realizamos estas acciones vamos a evitar la nidificación de estos roedores en vuestras instalaciones.</p>

                                    <p>El servicio de desratización permite disminuir la población de roedores presentes en un lugar determinado. Para realizar este control se dispone de variados métodos de control, entre los cuales destacan; elementos químicos y no químicos, además de realizar todas aquellas recomendaciones que ayude a evitar el ingreso, nidificación de estos vectores. Entre los métodos de control el rodenticida tiene que ser sobre base de algún cereal que guste al roedor, y que este sea de la mejor calidad posible. Porque es necesario también no solo el producto para que los elimine sino también productos que le gusten y estén mezclados con el rodenticida.</p>
                                    
                                    <p>También utilizamos nuestras estaciones de cebadero ROEDJOT ideal para industria de alimentos, centros de menores, lugares con mascotas. Las trampas de captura son ideales para la industria de alimentos que genera polvo, líquidos en piso y son aplicables a diversos cambios de temperatura.</p>
                                </Card.Text>
                            </Card.Body>

                            <Card.Body className="desratizacion__info1">
                                <Card.Title className="mb-2 text-muted">PROCESO DEL CONTROL DE ROEDORES</Card.Title>
                                <Card.Text className="desratizacion__info1--texto">
                                    <p>Para el control de roedores presentamos los trabajos previos, control físico, preparación de ambientes, tratamiento químico, así como actividades pos tratamiento que deben realizar las personas afectadas con el problema de roedores y la empresa de fumigación.</p>

                                    <p>Las ratas y ratones constituyen una de las principales y más peligrosas plagas urbanas con elevada capacidad de adaptación al medio y una extraordinaria fecundidad, con las siguientes particularidades:</p>
                                    <ul>
                                        <li>Tienen una vida media muy corta entre 18 a 20 meses pero pueden tener entre 10 a 12 camadas por año con 9 crias por camada aproximadamente.</li>
                                        <li>Son un importante vehiculo para transmición de muchas enfermedades al ser humano (FIEBRE TIFOIDEA,CÓLERA, SALMONELOSIS,TUBERCULOSIS, SARNA, BRUCELOSIS, PESTE, ETC).</li>
                                        <li>Se alimentan de cualquier materia orgánica a su dispocisión.</li>
                                        <li>Pueden vivir y reproducirse en condiciones de altas temperaturas.</li>
                                        <li>Soportan caidas letales de hasta altura de 20 metros y letales descargas electricas.</li>
                                        <li>Son un medio de transporte y propagación de ectoparásitos principalmente PULGAS y ÁCAROS.</li>
                                        <li>La Organización Mundial de la Salud (OMS) indica que a nivel mundial los roedores comen cada año 33 millones de toneladas de alimento lo suficiente para alimentar 130 millones de personas, pero el alimento que contaminan es mucho mayor, ampliandose asi los riesgos para la salud del ser humano..</li>
                                    </ul>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                <PopUpComponent/>
                <GalleriaComponent body={GalleriaItems_desratizacion}/>
                <Footer />
                <div className="widget-fb">
                    <MessengerCustomerChat
                    pageId="1853691534879265"
                    appId="293907205561035"
                    language= 'es_ES'
                    greeting_dialog_display ="fade"
                    greeting_dialog_delay = "2"
                />
                </div>
                
                <div className="widget-wsp">
                    <WhatsAppWidget 
                    phoneNumber='51973667643' 
                    companyName='Roedjot S.A.C' 
                    textReplyTime='Tipicamente responde a 2 minutos' 
                    message='¡Hola! 👋🏼 ¿Qué podemos hacer por usted?' 
                    sendButton='Enviar' 
                    placeholder='Escribe tu mensaje' 
                    />
                </div>
            </div>          
        )
    }
}
export default DesratizacionViewPresentation;