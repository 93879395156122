import React, { Component } from 'react';
import Navbar  from '../common/navbarComponent/Navbar';
import Footer from '../common/footerComponent/Footer';
import WhatsAppWidget from 'react-whatsapp-widget';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { Card } from 'react-bootstrap';

import './controlAves.css';

class ControlAvesViewPresentation extends Component{
    render(){
        return(
            <div>
                <Navbar />
                <div>
                    <img className="banner_servicio" src='img/controlAves/control-de-aves-urbanas.jpg' alt="Control de aves urbanas en Lima"></img>
                </div>
                <div className="tittle animate__animated animate__slideInUp">
                    <h2>CONTROL DE <span>AVES URBANAS</span></h2>
                </div>

                <Card className="controlAves animate__animated animate__slideInUp">
                            <Card.Body className="controlAves__info">
                                <Card.Text className="controlAves__info--cuerpo">
                                    La suciedad que generan a consecuencia de los excrementos, las plumas y el material de sus nidos, puede afectar a los sistemas de evacuación de agua de lluvia, obturándolos y como consecuencia generar humedades que pueden afectar la estructura del edificio. Sus excrementos también pueden causar importantes daños en edificios y mobiliario urbano ya que el ácido úrico que contiene es altamente corrosivo.
                                </Card.Text>

                                <Card.Text className="controlAves__info--cuerpo">
                                    Por este motivo pueden reducir a la mitad la vida media de una fachada o afectar gravemente las unidades de aire acondicionado y el sistema de recogida de aguas pluviales de los tejados o de patios interiores. también pueden constituir vectores de transmisión de enfermedades y pueden contaminar alimentos y depósitos de agua para el consumo.
                                </Card.Text>

                                <Card.Title className="mb-2 text-muted">¿SE PUEDEN CONTROLAR TODAS LAS ESPECIES DE AVES?</Card.Title>
                                <Card.Text className="controlAves__info--cuerpo">
                                    No, la mayor parte de las aves están protegidas, concretamente todas las ave insectívoras lo están. En este grupo encontramos aves típicamente de medio urbano como son las golondrinas y los vencejos. Están protegidos tanto las aves como su hábitat por lo que no puede eliminarse ningún nido sin la previa autorización de la administración competente. Por el contrario, las palomas y las gaviotas no están protegidas pero es necesaria una autorización municipal para su control. 
                                </Card.Text>
                            </Card.Body>

                            <Card.Body className="controlAves__info">
                                <Card.Title className="mb-2 text-muted">PARA EVITAR LA PROBLEMÁTICA CAUSADA POR LAS AVES HAY DIVERSOS TIPOS DE MEDIDAS</Card.Title>
                                <Card.Text className="controlAves__info--cuerpo">
                                        En la mayoría de caos se deberá combinar más de una medida para garantizar el éxito. Para escoger la mejor opción es preciso tener en cuenta la especie causante del problema, las características del lugar donde se deben implantar las medidas y la extensión de la zona a proteger.
                                </Card.Text>

                                <Card.Title className="mb-2 text-muted">LAS MEDIDAS DE EXCLUSIÓN</Card.Title>
                                <Card.Text className="controlAves__info--cuerpo">
                                        Son aquellas que tienen como objetivo romper el comportamiento habitual de las aves consiguiendo que el lugar deje de ser un atractivo para ellas o bien que no les ofrezca seguridad.
                                </Card.Text>
                                <Card.Title className="mb-2 text-muted">PRINCIPALES ESPECIES DE AVES PROBLEMÁTICAS</Card.Title>
                                <div className="controlAves__info--img">
                                    <Card.Img className="controlAves__info--img__1" src='img/controlAves/ave1.png' alt='control de aves urbanas-ave1' title='Control de aves Urbanas'></Card.Img>
                                    <Card.Img className="controlAves__info--img__2" src='img/controlAves/ave2.png' alt='control de aves urbanas-ave2' title='Control de aves Urbanas'></Card.Img>
                                </div>        
                            </Card.Body>
                        </Card>
                <Footer />
                <div className="widget-fb">
                    <MessengerCustomerChat
                    pageId="1853691534879265"
                    appId="293907205561035"
                    language= 'es_ES'
                    greeting_dialog_display ="fade"
                    greeting_dialog_delay = "2"
                />
                </div>

                <div className="widget-wsp">
                    <WhatsAppWidget 
                    phoneNumber='51973667643' 
                    companyName='Roedjot S.A.C' 
                    textReplyTime='Tipicamente responde a 2 minutos' 
                    message='¡Hola! 👋🏼 ¿Qué podemos hacer por usted?' 
                    sendButton='Enviar' 
                    placeholder='Escribe tu mensaje' 
                    />
                </div>
            </div>
            
        )
    }
}
export default ControlAvesViewPresentation;