export const GalleriaItems_desinfeccion = [
    {
        id:1,
        imagen: 'img/galleriaDesinfeccion/galleriaDesinfeccion1.jpg',
        alt:'Servicio de Desinfección en Lima'
    },

    {
        id:2,
        imagen: 'img/galleriaDesinfeccion/galleriaDesinfeccion2.jpg',
        alt:'Servicio de Desinfección en Lima'
    },

    {
        id:3,
        imagen: 'img/galleriaDesinfeccion/galleriaDesinfeccion3.jpg',
        alt:'Servicio de Desinfección en Lima'
    },

    {
        id:4,
        imagen: 'img/galleriaDesinfeccion/galleriaDesinfeccion4.jpg',
        alt:'Servicio de Desinfección en Lima'
    },

    {
        id:5,
        imagen: 'img/galleriaDesinfeccion/galleriaDesinfeccion5.jpg',
        alt:'Servicio de Desinfección en Lima'
    },

    {
        id:6,
        imagen: 'img/galleriaDesinfeccion/galleriaDesinfeccion6.jpg',
        alt:'Servicio de Desinfección en Lima'
    },

    {
        id:7,
        imagen: 'img/galleriaDesinfeccion/galleriaDesinfeccion7.jpg',
        alt:'Servicio de Desinfección en Lima'
    },

    {
        id:8,
        imagen: 'img/galleriaDesinfeccion/galleriaDesinfeccion8.jpg',
        alt:'Servicio de Desinfección en Lima'
    }
];

// otro objeto
export const GalleriaItems_desinsectacion = [
    {
        id:1,
        imagen: 'img/galleriaDesinsectacion/galleriaDesinsectacion1.jpg',
        alt:'Servicio de Desinsectación en Lima'
    },

    {
        id:2,
        imagen: 'img/galleriaDesinsectacion/galleriaDesinsectacion2.jpg',
        alt:'Servicio de Desinsectación en Lima'
    },

    {
        id:3,
        imagen: 'img/galleriaDesinsectacion/galleriaDesinsectacion3.jpg',
        alt:'Servicio de Desinsectación en Lima'
    },

    {
        id:4,
        imagen: 'img/galleriaDesinsectacion/galleriaDesinsectacion4.jpg',
        alt:'Servicio de Desinsectación en Lima'
    },

    {
        id:5,
        imagen: 'img/galleriaDesinsectacion/galleriaDesinsectacion5.jpg',
        alt:'Servicio de Desinsectación en Lima'
    },

    {
        id:6,
        imagen: 'img/galleriaDesinsectacion/galleriaDesinsectacion6.jpg',
        alt:'Servicio de Desinsectación en Lima'
    },

    {
        id:7,
        imagen: 'img/galleriaDesinsectacion/galleriaDesinsectacion7.jpg',
        alt:'Servicio de Desinsectación en Lima'
    },

    {
        id:8,
        imagen: 'img/galleriaDesinsectacion/galleriaDesinsectacion8.jpg',
        alt:'Servicio de Desinsectación en Lima'
    }
];

export const GalleriaItems_desratizacion = [
    {
        id:1,
        imagen: 'img/galleriaDesratizacion/galleriaDesratizacion1.jpg',
        alt:'Servicio de Desratización en Lima'
    },

    {
        id:2,
        imagen: 'img/galleriaDesratizacion/galleriaDesratizacion2.jpg',
        alt:'Servicio de Desratización en Lima'
    },

    {
        id:3,
        imagen: 'img/galleriaDesratizacion/galleriaDesratizacion3.jpg',
        alt:'Servicio de Desratización en Lima'
    },

    {
        id:4,
        imagen: 'img/galleriaDesratizacion/galleriaDesratizacion4.jpg',
        alt:'Servicio de Desratización en Lima'
    },

    {
        id:5,
        imagen: 'img/galleriaDesratizacion/galleriaDesratizacion5.jpg',
        alt:'Servicio de Desratización en Lima'
    },

    {
        id:6,
        imagen: 'img/galleriaDesratizacion/galleriaDesratizacion6.jpg',
        alt:'Servicio de Desratización en Lima'
    },

    {
        id:7,
        imagen: 'img/galleriaDesratizacion/galleriaDesratizacion7.jpg',
        alt:'Servicio de Desratización en Lima'
    },

    {
        id:8,
        imagen: 'img/galleriaDesratizacion/galleriaDesratizacion8.jpg',
        alt:'Servicio de Desratización en Lima'
    }
];

export const GalleriaItems_limpiezaAmbientes = [
    {
        id:1,
        imagen: 'img/galleriaLimpiezaAmbientes/galleriaLimpiezaAmbientes1.jpg',
        alt:'Servicio de Limpieza de Ambientes en Lima'
    },

    {
        id:2,
        imagen: 'img/galleriaLimpiezaAmbientes/galleriaLimpiezaAmbientes2.jpg',
        alt:'Servicio de Limpieza de Ambientes en Lima'
    },

    {
        id:3,
        imagen: 'img/galleriaLimpiezaAmbientes/galleriaLimpiezaAmbientes3.jpg',
        alt:'Servicio de Limpieza de Ambientes en Lima'
    },

    {
        id:4,
        imagen: 'img/galleriaLimpiezaAmbientes/galleriaLimpiezaAmbientes4.jpg',
        alt:'Servicio de Limpieza de Ambientes en Lima'
    },

    {
        id:5,
        imagen: 'img/galleriaLimpiezaAmbientes/galleriaLimpiezaAmbientes5.jpg',
        alt:'Servicio de Limpieza de Ambientes en Lima'
    }
];

export const GalleriaItems_limpiezaReservorios = [
    {
        id:1,
        imagen: 'img/galleriaLimpiezaReservorios/galleriaLimpiezaReservorios1.jpg',
        alt:'Servicio de Limpieza y Desinsectación de Reservorios de Agua en Lima'
    },

    {
        id:2,
        imagen: 'img/galleriaLimpiezaReservorios/galleriaLimpiezaReservorios2.jpg',
        alt:'Servicio de Limpieza y Desinsectación de Reservorios de Agua en Lima'
    },

    {
        id:3,
        imagen: 'img/galleriaLimpiezaReservorios/galleriaLimpiezaReservorios3.jpg',
        alt:'Servicio de Limpieza y Desinsectación de Reservorios de Agua en Lima'
    },

    {
        id:4,
        imagen: 'img/galleriaLimpiezaReservorios/galleriaLimpiezaReservorios4.jpg',
        alt:'Servicio de Limpieza y Desinsectación de Reservorios de Agua en Lima'
    },

    {
        id:5,
        imagen: 'img/galleriaLimpiezaReservorios/galleriaLimpiezaReservorios5.jpg',
        alt:'Servicio de Limpieza y Desinsectación de Reservorios de Agua en Lima'
    },

    {
        id:6,
        imagen: 'img/galleriaLimpiezaReservorios/galleriaLimpiezaReservorios6.jpg',
        alt:'Servicio de Limpieza y Desinsectación de Reservorios de Agua en Lima'
    },

    {
        id:7,
        imagen: 'img/galleriaLimpiezaReservorios/galleriaLimpiezaReservorios7.jpg',
        alt:'Servicio de Limpieza y Desinsectación de Reservorios de Agua en Lima'
    },

    {
        id:8,
        imagen: 'img/galleriaLimpiezaReservorios/galleriaLimpiezaReservorios8.jpg',
        alt:'Servicio de Limpieza y Desinsectación de Reservorios de Agua en Lima'
    }
];

export const GalleriaItems_limpiezaCampanas = [
    {
        id:1,
        imagen: 'img/galleriaLimpiezaCampanas/galleriaLimpiezaCampana1.jpg',
        alt:'Servicio de Limpieza de Campanas extractoras de Grasa en Lima'
    },

    {
        id:2,
        imagen: 'img/galleriaLimpiezaCampanas/galleriaLimpiezaCampana2.jpg',
        alt:'Servicio de Limpieza de Campanas extractoras de Grasa en Lima'
    },

    {
        id:3,
        imagen: 'img/galleriaLimpiezaCampanas/galleriaLimpiezaCampana3.jpg',
        alt:'Servicio de Limpieza de Campanas extractoras de Grasa en Lima'
    },

    {
        id:4,
        imagen: 'img/galleriaLimpiezaCampanas/galleriaLimpiezaCampana4.jpg',
        alt:'Servicio de Limpieza de Campanas extractoras de Grasa en Lima'
    }
];

export const GalleriaItems_limpiezaTrampa = [
    {
        id:1,
        imagen: 'img/galleriaLimpiezaTrampa/galleriaLimpiezaTrampa1.jpg',
        alt:'Servicio de Limpieza de Trampas de Grasa'
    },

    {
        id:2,
        imagen: 'img/galleriaLimpiezaCampanas/galleriaLimpiezaCampana4.jpg',
        alt:'Servicio de Limpieza de Trampas de Grasa'
    }
]