import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { InicioViewPresentation } from "../inicioComponent";

class InicioViewContainer extends Component {
    constructor(props) {
    super(props);
    }

    componentDidUpdate(prevProps) {}


render() {
    return (
        <div className={this.props.isLoading ? "card card-with-opacity" : "card"}>
        <InicioViewPresentation
            form={this.props.exampleComponentForm}
            updateFieldState={this.updateFieldState}
        />
    </div>
    );
    }
}

export default InicioViewContainer;

