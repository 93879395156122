import React, { Component } from 'react';
import './ContactoComponent.css';
import emailjs from 'emailjs-com';

class ContactoComponent extends Component{
        render(){

            function enviarEmail(e){
                e.preventDefault();  
                emailjs.sendForm('service_cy2is7l', 'template_62q2b63', e.target, 'user_1P1YeZgl4vQuEGp6AhJBf')
                  .then((result) => { 
                      window.gtag('event', 'conversion', {'send_to': 'AW-863222461/SlmYCJbYs-gCEL31zpsD'});
                      alert('Mensaje Enviado Correctamente! Gracias por Comunicarte con nosotros. Atte.Roedjot S.A.C.');
                  }, (error) => {
                      alert(error.message)
                  });
                  e.target.reset();
            }

        return(

        <div>
            <div className="contacto animate__animated animate__backInDown">
            <form onSubmit={enviarEmail}>
                    <div className="row pt-5 mx-auto">             
                        <div className="col-lg-8 col-sm-12 form-group mx-auto">
                            <label className="label__form">Nombre o Razón Social :</label>
                            <input type="text" autoFocus className="form-control" required name="name"/>
                        </div>

                        <div className="col-lg-8 col-sm-12 form-group pt-1 mx-auto">
                            <label className="label__form">Dirección :</label>
                            <input type="text" className="form-control" required name="direction"/>
                        </div>

                        <div className="col-lg-8 col-sm-12 form-group pt-1 mx-auto">
                            <label className="label__form">Teléfono :</label>
                            <input type="text" className="form-control" required name="phone"/>
                        </div>

                        <div className="col-lg-8 col-sm-12 form-group pt-1 mx-auto">
                            <label className="label__form">Correo :</label>
                            <input type="email" className="form-control" required name="email"/>
                        </div>

                        <div className="col-lg-8 col-sm-12 form-group pt-1 mx-auto">
                            <label className="label__form">Mensaje :</label>
                            <textarea className="form-control" cols="30" rows="10" required placeholder="Escríbenos tu requerimiento o solicitud..." name="message"></textarea>
                        </div>
                        <div className="col-lg-8 col-sm-12 pt-3 mx-auto">
                            <input type="submit" className="btn btn-info" value="Enviar Solicitud"></input>
                        </div>
                    </div>
                </form>
                <img className="contact__img" src='img/contacto/contacto.jpg'></img>
            </div>
        </div>
        );
    }
}
export default ContactoComponent;