import React, { Component } from "react";
import Navbar from "../common/navbarComponent/Navbar";
import Footer from  "../common/footerComponent/Footer";
import WhatsAppWidget from 'react-whatsapp-widget';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import Mision from '../common/misionComponent/Mision';
import Objetivos from '../common/objetivosComponent/Objetivos';
import Personal from '../common/personalComponent/Personal';
import Header from "../common/headerComponent/Header";

import './nosotros.css';
import 'animate.css/animate.min.css';
import 'react-whatsapp-widget/dist/index.css'


const NosotrosViewPresentation= ({
    form,
}) => {
    
    return (
        <div>     
            <Navbar />
            <Header />
                <Mision />
                <Objetivos />
                <div className="tittle">
                    <h2>Nuestro <span>Equipo</span></h2>
                </div>
                    <Personal />

                <div className="widget-fb">
                    <MessengerCustomerChat
                    pageId="1853691534879265"
                    appId="293907205561035"
                    language= 'es_ES'
                    greeting_dialog_display ="fade"
                    greeting_dialog_delay = "2"
                />
                </div>

                <div className="widget-wsp">
                    <WhatsAppWidget 
                    phoneNumber='51973667643' 
                    companyName='Roedjot S.A.C' 
                    textReplyTime='Tipicamente responde a 2 minutos' 
                    message='¡Hola! 👋🏼 ¿Qué podemos hacer por usted?' 
                    sendButton='Enviar' 
                    placeholder='Escribe tu mensaje' 
                />
                </div>
            <Footer/>
             
        </div>
    );
};

export default NosotrosViewPresentation;