import React, { Component } from 'react';
import { Galleria } from 'primereact/galleria';
import './GalleriaComponent.css';

class GalleriaComponent extends Component {

    constructor(props) {
        super(props);

        this.itemTemplate = this.itemTemplate.bind(this);
        this.thumbnailTemplate = this.thumbnailTemplate.bind(this);

        this.responsiveOptions = [
            {
                breakpoint: '1024px',
                numVisible: 5
            },
            {
                breakpoint: '960px',
                numVisible: 4
            },
            {
                breakpoint: '768px',
                numVisible: 3
            },
            {
                breakpoint: '560px',
                numVisible: 1
            }
        ];
    }

    itemTemplate(item) {
        return <img src={item.imagen} alt={item.alt} style={{ width: '100%', display: 'block' }} />
    }

    thumbnailTemplate(item) {
        return <img src={item.imagen} alt={item.alt} style={{ width:'150px', display: 'block' }} />
    }

    render() {
        return (
            <div className="galleria__nosotros">
                <div className="card">
                    <Galleria value={this.props.body} responsiveOptions={this.responsiveOptions} numVisible={7} circular style={{ maxWidth: '800px' }}
                        item={this.itemTemplate} thumbnail={this.thumbnailTemplate} circular autoPlay transitionInterval={2000} />
                </div>
            </div>
        );
    }
}

export default GalleriaComponent;
 