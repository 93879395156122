import React, { Component } from 'react';
import { Card } from 'react-bootstrap';

import './Mision.css';


class Mision extends Component {
    render(){
        return(
            <Card className="mision-vision">
                <Card.Body className="card__body">
                    <Card.Title className="mb-2 text">
                        Nuestra Visión
                    </Card.Title>
                    <Card.Text className="card__description">
                    Ser una empresa sólida en la prestación de servicios de manejo integrado de plagas (MIP), manteniendo la calidad de los productos utilizados y servicios prestados buscando, de esta manera la confianza y seguridad de nuestros clientes. De esta manera buscamos incursionar en el territorio nacional en el control de plagas urbanas, mantener el reconocimiento de nuestros clientes como una empresa seria, comprometida y con alta calidad en nuestros servicios.
                    </Card.Text>
                    <br/>
                    <Card.Title className="mb-2 text">
                        Nuestra Misión
                    </Card.Title>
                    <Card.Text className="card__description">
                        Proveer soluciones confiables de alta calidad para la limpieza y control de plagas, mediante el desarrollo, tecnología de nuestros equipos, la aplicación de métodos innovadores, la comercialización de productos y la prestación de servicios, impulsados por nuestro talento humano a fin de lograr la fidelidad y satisfacción total de nuestros clientes a través de la solución dinámica y profesional de sus problemas y necesidades.
                    </Card.Text>
                </Card.Body>
                <div className="card__image">
                    <Card.Img  className="card__image--info" variant="top" src='img/nosotros/personal1.png' onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}/>
                        <p className="card__image--info__titulo">JUAN JOSE ROJAS PEÑA</p>
                        <p className="card__image--info__subtitulo"><b>Gerente General</b></p>
                </div>              
            </Card>
        )
    }
}

export default Mision;