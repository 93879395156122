import React, { Component } from 'react';
import Navbar  from '../common/navbarComponent/Navbar';
import Footer from '../common/footerComponent/Footer';
import WhatsAppWidget from 'react-whatsapp-widget';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { Card } from 'react-bootstrap';
import PopUpComponent from '../common/popUpContacComponent/PopUpComponent';
import GalleriaComponent from '../common/galleriaComponent/GalleriaComponent';
import { GalleriaItems_desinfeccion } from '../common/galleriaComponent/Galerias';

import './desinfeccion.css'

class DesinfeccionViewPresentation extends Component{
    render(){
        return(
            <div>
                <Navbar />
                {/* 
                <div className="video__servicio">
                    <video autoplay controls>
                        <source src={video1} type="video/mp4"></source>
                    </video>
                </div>
                */}
                <div>
                    <img className="banner_servicio" src='img/desinfeccion/desinfeccion-roedjot.jpg' alt="Servicio de desinfección en lima"></img>
                </div>
                <div className="tittle animate__animated animate__slideInUp">
                    <h2>SERVICIO DE <span>DESINFECCION</span></h2>
                </div>

                <Card className="desinfeccion animate__animated animate__slideInUp">
                    <Card.Body className="desinfeccion__info">
                        <Card.Text className="desinfeccion__info--cuerpo">
                            <p>Actividad con la cual se controla microorganismos y gérmenes patógenos, como ácaros, hongos y neutralizar virus ambientales. Mediante el empleo de fungicidas, viricidas y bactericidas.</p>

                            <p>Proceso consistente en la eliminacion de ácaros y microorganismos patógenos que pululan en el medio ambiente y que son transmisores de enfermedades infecto contagiosas, reduciéndolos a un nivel que no dañe la salud de las personas ni la calidad de los bienes. Se aplican productos de marcas de calidad, los que al contacto con el ser humano no produce transtorno alguno, los cuales están autorizados por el Ministerio de Salud.</p>
                        </Card.Text>
                    </Card.Body>

                    <Card.Body className="desinfeccion__info">
                        <Card.Title className="mb-2 text-muted">REDUCCIÓN DE BACTERIAS</Card.Title>
                        <Card.Text className="desinfeccion__info--cuerpo">
                            <p>Control del crecimiento microbiano que permite eliminar toda la forma de vida como virus y esporas. De este modo, la esterilización se encarga de la destrucción de los microorganismos que se hallan en un objeto, sustancia o lugar. La aplicación de desinfectantes como el amonio cuaternario (EXQUAT 50), ayudara a controlar en el ambiente el tratado de bacterias nocivas para el ser humano, esto a través de la ruptura de su pared celular. La disminución de estas, generara un mejor ambiente para la persona, evitando cualquier tipo de enfermedades causadas por bacterias comunes.</p>
                            
                            <p>Para ello podemos usar diversos métodos de aplicación:</p>
                                <ul>
                                    <li>Impregnación del objeto, sustancia o lugar</li>
                                    <li>Pulverización.</li>
                                    <li>Nebulización.</li>
                                    <li>Fumigación.</li>
                                </ul>
                            <p>Que lograrán diversos modos de acción:</p>
                                <ul>
                                    <li>Acción bacteriana (destruye la vida bacteriana).</li>
                                    <li>Acción virucida (capaz de destruir los virus).</li>
                                    <li>Acción bacteriostática (impide la multiplicación de las bacterias).</li>
                                    <li>Acción esporicida (destruye esporas logrando la esterilización).</li>
                                </ul>
                        </Card.Text>
                    </Card.Body>
                </Card>
                <PopUpComponent/>                
                <GalleriaComponent body={GalleriaItems_desinfeccion}  />      
                <Footer/>  

                <div className="widget-fb">
                    <MessengerCustomerChat
                    pageId="1853691534879265"
                    appId="293907205561035"
                    language= 'es_ES'
                    greeting_dialog_display ="fade"
                    greeting_dialog_delay = "2"
                />
                </div>
                    
                <div className="widget-wsp">
                    <WhatsAppWidget 
                    phoneNumber='51973667643' 
                    companyName='Roedjot S.A.C' 
                    textReplyTime='Tipicamente responde a 2 minutos' 
                    message='¡Hola! 👋🏼 ¿Qué podemos hacer por usted?' 
                    sendButton='Enviar' 
                    placeholder='Escribe tu mensaje'/>
                </div>
            </div>       
        )
    }
}
export default DesinfeccionViewPresentation;