import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { ManttoTrampaViewPresentation } from '../manttoTrampaComponent';

class ManttoTrampaViewContainer extends Component {
    render(){
        return(
            <div className={this.props.isLoading ? "card card-with-opacity" : "card"}>
            <ManttoTrampaViewPresentation />
            </div>
        )
    }
}
export default ManttoTrampaViewContainer;
