import React, {Component} from 'react';
import { GMap } from 'primereact/gmap';
/*import {FooterItems} from './FooterItems';*/
import { FaFacebookSquare, FaYoutube, FaInstagramSquare, FaPhoneSquareAlt, FaHome, FaHandsHelping } from "react-icons/fa";
import { MdPhoneAndroid, MdImportContacts, MdEmail } from 'react-icons/md';
import { SiGooglemaps, SiTiktok } from 'react-icons/si';
import { HiUserGroup } from 'react-icons/hi';

import './footer.css'

class Footer extends Component {

    render() {

        const options = {
            center: {lat: -11.89001, lng: -77.03133},
            zoom: 17
        };

        const overlays = [
            new window.google.maps.Marker({position: {lat: -11.89001, lng: -77.03133}, title: "Roedjot S.A.C"})
        ];

        return (
            <div className="main-footer">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h4 className="footer-title">Saneamiento Roedjot SAC</h4>
                            <ul className="list-unstyled">
                                <li className="links__info">
                                    <span>
                                        <FaPhoneSquareAlt className="links__info--icon" size="30px"/>
                                    </span>
                                    <span>01-6374-597</span>
                                </li>
                                <li className="links__info">
                                    <span>
                                        <MdPhoneAndroid className="links__info--icon" size="30px"/>
                                    </span>
                                    <span>973-667-643</span>
                                </li>
                                <li className="links__info">
                                    <span>
                                        <MdPhoneAndroid className="links__info--icon" size="30px"/>
                                    </span>
                                    <span>992-842-820</span>
                                </li>
                                <li className="links__info">
                                    <span>
                                        <MdEmail className="links__info--icon" size="30px"/>
                                    </span>
                                    <span>ventas@roedjot.com</span>
                                </li>
                                <li className="links__info">
                                    <span>
                                        <SiGooglemaps className="links__info--icon" size="30px"/>
                                    </span>
                                    <span>Mz E Lt32 Asoc. Estrella Solar Carabayllo, Lima</span>
                                </li>
                            </ul>
                        </div>

                        <div className="col">
                            <h4 className="footer-title">Ubícanos</h4>
                            <ul className="list-unstyled">
                                <GMap overlays={overlays} options={options} style={{width: '330px', minHeight: '200px'}} />
                            </ul>
                        </div>

                        <div className="col">
                            <h4 className="footer-title">Contenido</h4>
                            <ul className="list-unstyled">
                                <li><a href="./#/inicio" className="links_tab"><FaHome className="links_tab--icon" size="30px"/>Inicio</a></li>
                                <li><a href="./#/nosotros" className="links_tab"><HiUserGroup className="links_tab--icon" size="30px"/>Nosotros</a></li>
                                <li><a href="./#/servicios" className="links_tab"><FaHandsHelping className="links_tab--icon" size="30px"/>Servicios</a></li>
                                <li><a href="./#/contacto" className="links_tab"><MdImportContacts className="links_tab--icon" size="30px"/>Contáctanos</a></li>
                            </ul>
                        </div>

                        <div className="col">
                            <h4 className="footer-title">Nuestras Redes</h4>
                            <ul className="list-unstyled">
                            
                                <li><a href="https://www.tiktok.com/@grupo_roedjot?" className="links__social" target="__blank"><SiTiktok size="30px" className="links__social--icon"/>TikTok</a></li>
                                <li><a href="https://www.facebook.com/roedjot/" className="links__social" target="__blank"><FaFacebookSquare size="30px" className="links__social--icon"/>Facebook</a></li>
                                <li><a href="https://www.youtube.com/channel/UCRvNj9qEgI1xr-y44XX_koA/featured" className="links__social" target="__blank"><FaYoutube size="30px" className="links__social--icon"/>Youtube</a></li>
                                <li><a href="https://www.instagram.com/roedjot_sac/?hl=es-la" className="links__social" target="__blank"><FaInstagramSquare size="30px" className="links__social--icon"/>Instagram</a></li>
                            </ul>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                            <p className="col-sm">
                                &copy;{new Date().getFullYear()} Roedjot S.A.C | Todos los derechos reservados | Términos y Servicios | Privacidad
                            </p>
                    </div>
                </div>
            </div>
        )
    }
}
export default Footer;
