import React, { Component } from 'react';
import Persona  from '../personaComponent/Persona';
import { PersonalItems } from './PersonalItems';

import './Personal.css';

class Personal extends Component{
    render(){
        return(
            <div className="personal">
                {
                    PersonalItems.map(persona =>(
                        <div className="col-md-3" key={persona.id}>
                            <Persona name={persona.name} 
                            cargo={persona.cargo} image={persona.image} descripcion={persona.descripcion} />
                        </div>
                    ))
                }
            </div>
        )
    }
}

export default Personal;