import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { NotFound } from './components/common/not-found';
import { InicioViewContainer } from './components/inicioComponent';
import { NosotrosViewContainer } from './components/nosotrosComponent';
import { ServiciosViewContainer } from './components/serviciosComponent';
import { ContactoViewContainer } from './components/contactoComponent';
import { DesinsectacionViewContainer } from './components/desinsectacionComponent';
import { DesinfeccionViewContainer } from './components/desinfeccionComponent';
import { DesratizacionViewContainer } from './components/desratizacionComponent';
import { LimpiezaAreasViewContainer } from './components/limpiezaAreasComponent';
import { ManttoCampanaViewContainer } from './components/manttoCampanaComponent';
import { ManttoReservoriosViewContainer } from './components/manttoReservoriosComponent';
import { ManttoTrampaViewContainer } from './components/manttoTrampaComponent';
import { ControlAvesViewContainer } from './components/controlAvesComponent';

export default function AppRouter(props) {
    return (
        <Switch>
            <Route exact path='/inicio' component = {InicioViewContainer} />
            <Route exact path='/nosotros' component = {NosotrosViewContainer} />
            <Route exact path='/servicios' component ={ServiciosViewContainer} />
                <Route exact path='/servicios/desinsectacion' component ={DesinsectacionViewContainer} />
                <Route exact path='/servicios/desinfeccion' component ={DesinfeccionViewContainer} />
                <Route exact path='/servicios/desratizacion' component ={DesratizacionViewContainer} />
                <Route exact path='/servicios/limpiezaAreas' component ={LimpiezaAreasViewContainer} />
                <Route exact path='/servicios/manttoCampana' component ={ManttoCampanaViewContainer} />
                <Route exact path='/servicios/manttoReservorios' component ={ManttoReservoriosViewContainer} />
                <Route exact path='/servicios/manttoTrampa' component ={ManttoTrampaViewContainer} />
                <Route exact path='/servicios/controlAves' component ={ControlAvesViewContainer} />
            <Route exact path='/contacto' component ={ContactoViewContainer} />
            <Route component = {InicioViewContainer }></Route>
        </Switch>
    )
}