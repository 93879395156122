import React, { Component } from 'react';
import {FaInstagramSquare } from 'react-icons/fa';
import { FaFacebookSquare } from 'react-icons/fa';
import { FaLinkedin } from 'react-icons/fa';

import './Persona.css';

class Persona extends Component{
    render(){
        return(
            <div className="persona text-center animate__animated animate__fadeInUp">
                <div className="face front card-body">
                    <img className="imagen" src={this.props.image} alt="Saneamiento y Fumigación"></img>
                    <h4 className="name">{this.props.name}</h4>
                    <h3 className="cargo">{this.props.cargo}</h3>
                </div>
                <div className="face back">
                    <p className="descripcion">{this.props.descripcion}</p>
                </div>
            </div>
        )
    }
}

export default Persona;