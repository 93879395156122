import React, { Component } from 'react';
import Card from '../cardComponent/Card';
import {CardsItems} from './CardsItems';

import './Cards.css';

class Cards extends Component{
    render(){
    return(
        <div className="container d-flex justify-content-center align-items-center h-100">
            <div className="row">
                {
                    CardsItems.map(card =>(
                        <div className="col-md-3" key={card.id}>
                            <Card title={card.title} image={card.image} url={card.url} content={card.content} />
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
}

export default Cards;