import React, { Component } from 'react';
import Navbar  from '../common/navbarComponent/Navbar';
import Footer from '../common/footerComponent/Footer';
import WhatsAppWidget from 'react-whatsapp-widget';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { Card } from 'react-bootstrap';
import PopUpComponent from '../common/popUpContacComponent/PopUpComponent';

import './manttoReservorios.css';
import GalleriaComponent from '../common/galleriaComponent/GalleriaComponent';
import { GalleriaItems_limpiezaReservorios } from '../common/galleriaComponent/Galerias';
//import video1 from './media/mantto-reservorio-roedjot.mp4'

class ManttoReservoriosViewPresentation extends Component{
    render(){
        return(
            <div>
                <Navbar />
                {/*
                <div className="video__servicio">
                    
                    <video autoplay controls>
                        <source src={video1} type="video/mp4"></source>
                    </video>
                </div>
                */}
                <div>
                    <img className="banner_servicio" src='img/limpiezaReservorio/limpieza-de-reservorios-de-agua.jpg' alt="Servicio de limpieza de reservorios de agua en lima"></img>
                </div>
                <div className="tittle animate__animated animate__slideInUp">
                    <h2>LIMPIEZA Y DESINFECCIÓN <span>DE RESERVORIOS DE AGUA</span></h2>
                </div>

                <Card className="manttoReservorios animate__animated animate__slideInUp">
                        <Card.Body className="manttoReservorios__info">
                            <Card.Text className="manttoReservorios__info--cuerpo">
                                <p>De acuerdo a la norma sanitaria vigente, el servicio de desinfección y limpieza de cisternas y tanques, se debe de realizar cuando menos dos veces al año. Esta recomendación es para cumplir con estándares de seguridad sanitaria, ya que el agua que viene de la atarjea, por más tratada y clorada que se le realice para que ingrese pura a la red de agua, está siempre llega a pasar barro formándose un cieno y fango que se vuelve caldo de cultivo para la reproducción de bacterias, hongos y virus.</p>

                                <p>El proceso de limpieza de cisternas comienza con el cerrado de la llave de agua que permite el ingreso a la cisterna, luego evacuar el agua mediante un electro bomba de succión y descarga tratando de dejar un mínimo de agua de 15 centímetros.</p>
                            </Card.Text>
                        </Card.Body>

                        <Card.Body className="manttoReservorios__info">
                            <Card.Text className="manttoReservorios__info--cuerpo">
                                <p>Con esa agua se comienza a cepillar pisos, paredes y techos con escobillas de cerdas plásticas duras para evitar rayar las capas de impermeabilización de las estructuras de almacenamiento, luego se elimina toda suciedad o material extraña para luego proceder a evacuar todo el fango, cieno y barro formado en el piso con nuestros equipos de bombeo.</p>

                                <p>Luego se procede con el enjuague y a la desinfección mediante el método de Cloración, con Hipoclorito de Sodio, asegurándonos que todas las superficies de las estructuras de almacenamiento entren en contacto con el cloro. El producto que utilizamos como desinfectante es el amonio cuaternario. De conformidad con las normas que establece el código sanitario vigente, otorgamos un certificado que acredita la realización de desinfección y limpieza de cisternas y tanques, según norma expedida por la Unidad Departamental de Lima Ciudad del Ministerio de Salud, y reconocido por la Municipalidad de su distrito.</p>
                            </Card.Text>
                        </Card.Body>
                </Card>
                <PopUpComponent/>
                <GalleriaComponent body ={GalleriaItems_limpiezaReservorios} />
                <Footer />
                <div className="widget-fb">
                <MessengerCustomerChat
                    pageId="1853691534879265"
                    appId="293907205561035"
                    language= 'es_ES'
                    greeting_dialog_display ="fade"
                    greeting_dialog_delay = "2"
                />
                </div>
                
                <div className="widget-wsp">
                    <WhatsAppWidget 
                    phoneNumber='51973667643' 
                    companyName='Roedjot S.A.C' 
                    textReplyTime='Tipicamente responde a 2 minutos' 
                    message='¡Hola! 👋🏼 ¿Qué podemos hacer por usted?' 
                    sendButton='Enviar' 
                    placeholder='Escribe tu mensaje' 
                    />
                </div>
            </div> 
        )
    }
}
export default ManttoReservoriosViewPresentation;