import React, { Component } from 'react';
import './Header.css';
import { Card } from 'react-bootstrap';

class Header extends Component{
    render(){
        return(
            <div>
                <img className="banner__nosotros" src='img/nosotros/headerNos.jpg' alt="Servicios de Fumigación y control de plagas" title="Especialistas en desinfección anticovid, fumigación integral y control de plagas "></img>
                    
                <Card>
                    <Card.Body className="info__header">
                        <Card.Title className="info__header--title">Nuestra Política</Card.Title>
                        <Card.Text  className="info__header--text">
                            <p>En Saneamiento Roedjot S.A.C somos especialistas en proporcionar servicios de control y prevención de todo tipo de plagas. A lo largo de nuestra trayectoria hemos acumulado prestigio en Lima y provincias.</p>
            
                            <p>Nos hemos convertido en la empresa lider en el rubro de fumigaciones y control de plagas. Nuestro mercado lo constituye las empresas industriales y comerciales más importantes del país, así como un gran número de hogares a los cuales se les ofrece una protección permanente.</p>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>
        )
    }
}

export default Header;