import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { LimpiezaAreasViewPresentation } from '../limpiezaAreasComponent';

class LimpiezaAreasViewContainer extends Component {
    render(){
        return(
            <div className={this.props.isLoading ? "card card-with-opacity" : "card"}>
            <LimpiezaAreasViewPresentation />
            </div>
        )
    }
}
export default LimpiezaAreasViewContainer;
