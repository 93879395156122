import React from 'react';
import { Component } from 'react';
import WhatsAppWidget from 'react-whatsapp-widget';

import './WspComponent.css';

class WspComponent extends Component{
    render(){
        return(
            <div className="Enlace__wsp">
                Comunicate con uno de nuestros especialistas:
                <div className="wsp">
                    <WhatsAppWidget
                    phoneNumber='51973667643' 
                    companyName='Roedjot S.A.C' 
                    textReplyTime='Tipicamente responde a 2 minutos' 
                    message='¡Hola! 👋🏼 ¿Qué podemos hacer por usted?' 
                    sendButton='Enviar' 
                    placeholder='Escribe tu mensaje'/>
                </div>
            </div>
        )
    }
}

export default WspComponent;