import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { DesratizacionViewPresentation } from '../desratizacionComponent';

class DesratizacionViewContainer extends Component {
    render(){
        return(
            <div className={this.props.isLoading ? "card card-with-opacity" : "card"}>
            <DesratizacionViewPresentation />
            </div>
        )
    }
}
export default DesratizacionViewContainer;
