import React, { Component } from 'react';
import AppRouter from './routes';
//import './App.css';

class App extends Component {
  render(){
  return (
    <div className="App" id="App">
      <AppRouter />
    </div>
    );
  }
}

export default App;
