import React, { Component } from "react";
import Navbar from "../common/navbarComponent/Navbar";
import Slider from "../common/sliderComponent/Slider";
import Info from "../common/infoComponent/Info";
import Cards from "../common/cardsComponent/Cards";
import Carousell from "../common/carouselCliComponent/Carousell";
import Footer from  "../common/footerComponent/Footer";
import WhatsAppWidget from 'react-whatsapp-widget';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import WspComponent from "../common/enlaceWspComponent/WspComponent";
import './inicio.css';
import 'animate.css/animate.min.css';
import 'react-whatsapp-widget/dist/index.css'

const InicioViewPresentation= ({
    form,
}) => {
    return (
        <div>
            <Navbar />
            <Slider />
            <WspComponent />
            <div className= "tittle">
                <h2>Nuestros <span>Servicios</span></h2>
                {/*<div className="line"></div>*/ }
            </div>

            <div className="services">
                <Cards/>
            </div>

            <div className="fondo-1">
                <br/>
                <div className="tittle">
                    <h2>Nuestra <span>Compañia</span></h2>
                </div>
                    <Info/>
            </div>

            <div className="tittle">
                <h2>Nuestros <span>Clientes</span></h2>
            </div>
            <Carousell/>
            <div className="widget-fb">
                <MessengerCustomerChat
                pageId="1853691534879265"
                appId="293907205561035"
                language= 'es_ES'
                greeting_dialog_display ="fade"
                greeting_dialog_delay = "2"
            />
            </div>

            <div className="widget-wsp">
                <WhatsAppWidget 
                phoneNumber='51973667643' 
                companyName='Roedjot S.A.C' 
                textReplyTime='Típicamente responde a 2 minutos' 
                message='¡Hola! 👋🏼 ¿Qué podemos hacer por usted?' 
                sendButton='Enviar' 
                placeholder='Escribe tu mensaje'
            />
            </div>
            <Footer/>
        </div>
    );
};

export default InicioViewPresentation;