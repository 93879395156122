import React, { Component } from 'react';
import './Card.css';

class Card extends Component{
    render(){
    return(
        <div className="solo text-center bg-success animate__animated animate__fadeInUp">
            <div className="overflow">
                <img className="image__card" src={this.props.image} alt="Saneamiento y Fumigación"></img>
            </div>
            <div className="card-body">
                <h4 className="card-title text-light">{this.props.title}</h4>
                <p className="card-text text-light">{this.props.content}</p>
                <a href={this.props.url} className="link btn btn-outline-info">Leer M&aacute;s</a>
            </div>
            
        </div>
    )
}
}

export default Card;