import React, { Component } from 'react';
import Navbar  from '../common/navbarComponent/Navbar';
import Footer from '../common/footerComponent/Footer';
import WhatsAppWidget from 'react-whatsapp-widget';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { Card } from 'react-bootstrap';
import PopUpComponent from '../common/popUpContacComponent/PopUpComponent';

import './manttoTrampa.css';
import GalleriaComponent from '../common/galleriaComponent/GalleriaComponent';
import { GalleriaItems_limpiezaTrampa } from '../common/galleriaComponent/Galerias';

class ManttoTrampaViewPresentation extends Component{
    render(){
        return(
            <div>
                <Navbar />
                <img className="banner_servicio" src='img/limpiezaTrampa/limpieza-de-trampas-de-grasa.jpg' alt="Servicio de limpieza de trampas de grasa"></img>
                <div className="tittle animate__animated animate__slideInUp">
                    <h2>LIMPIEZA Y MANTENIMIENTO <span>DE TRAMPAS DE GRASA</span></h2>
                </div>

                <Card className="manttoTrampa animate__animated animate__slideInUp">
                            <Card.Body className="manttoTrampa__info">
                                <Card.Text className="manttoTrampa__info--cuerpo">
                                    <p>Todo equipo de trampa de grasa requiere una limpieza regular y comprobaciones para asegurarse de que funcionan correctamente y desarrollar un trabajo para el que están diseñados para llevar a cabo. Nuestro equipo de expertos de eliminación de grasa altamente capacitados puede limpiar las trampas de grasa y eliminar la grasa sobrante de manera segura y eficiente.</p>
                                </Card.Text>

                                <Card.Text className="manttoTrampa__info--cuerpo">
                                    <p>Nos aseguraremos de que su sistema de trampa de grasa funciona de manera eficiente, por medio de un programa de mantenimiento, obteniendo el máximo beneficio para su negocio, así como la garantía de que está en el cumplimiento de las directrices de la norma vigente VMA y los programas de gestión de la autoridad local.</p>
                                </Card.Text>

                                <Card.Text className="manttoTrampa__info--cuerpo">
                                    <p>Mediante el mantenimiento de las trampas de grasa vamos a establecer un programa de mantenimiento adecuado que eliminará completamente el uso de químicos dañinos o corrosivos de su equipo. Un gran número de nuestros clientes han eliminado por completo estos sistemas de dosificación caros e hizo un ahorro sustancial cuando cambiaron a un programa de mantenimiento regular.</p>
                                </Card.Text>
                            </Card.Body>

                            <Card.Body className="manttoTrampa__info">
                                <Card.Title className="mb-2 text-muted">En SANEAMIENTO ROEDJOT S.A.C., ofrecemos más que un servicio de mantenimiento de trampa de grasa: </Card.Title>
                                <Card.Text className="manttoTrampa__info--cuerpo">
                                    <ul>
                                        <li> El personal cualificado. Nuestros técnicos formados profesionalmente mantendrán su trampa de grasa con eficiencia y esmero.</li>
                                        <li> Confiabilidad. Puede contar con nosotros, haremos todo lo posible para evitar la interrupción de sus operaciones.</li>
                                        <li> Responsabilidad. Usted recibirá un informe completo de la condición de su trampa de grasa después de cada visita.</li>
                                        <li> Tranquilidad. El mantenimiento regular reduce el riesgo de resbalones y caídas de las trampas de grasa desbordantes y ayuda a evitar la necesidad de reparaciones de emergencia..</li>
                                        <li> Un medio ambiente más verde. SANEAMIENTO ROEDJOT S.A.C. protege las fuentes de agua locales, manteniendo la grasa y el aceite para que alcancen las alcantarillas municipales, también renovamos toda la grasa que recogemos.</li>
                                    </ul>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                <PopUpComponent/>
                <GalleriaComponent body={GalleriaItems_limpiezaTrampa} />
                <Footer />
                <div className="widget-fb">
                <MessengerCustomerChat
                    pageId="1853691534879265"
                    appId="293907205561035"
                    language= 'es_ES'
                    greeting_dialog_display ="fade"
                    greeting_dialog_delay = "2"
                />
                </div>

                <div className="widget-wsp">
                    <WhatsAppWidget 
                    phoneNumber='51973667643' 
                    companyName='Roedjot S.A.C' 
                    textReplyTime='Tipicamente responde a 2 minutos' 
                    message='¡Hola! 👋🏼 ¿Qué podemos hacer por usted?' 
                    sendButton='Enviar' 
                    placeholder='Escribe tu mensaje' 
                    />
                </div>
            </div>
            
        )
    }
}
export default ManttoTrampaViewPresentation;