import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { DesinsectacionViewPresentation } from '../desinsectacionComponent';

class DesinsectacionViewContainer extends Component {
    render(){
        return(
            <div className={this.props.isLoading ? "card card-with-opacity" : "card"}>
                <DesinsectacionViewPresentation />
            </div>
        )
    }
}
export default DesinsectacionViewContainer;