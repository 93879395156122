import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import ReactDOM from "react-dom";

import React, { Component } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import ContactoComponent from '../contactoComponent/ContactoComponent';

import "./PopUpComponent.css";

class PopUpComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayResponsive: false,
      position: "center"
    };

    this.onClick =  this.onClick.bind(this);
    this.onHide = this.onHide.bind(this);
  }

  onClick(name, position) {
    let state = {
      [`${name}`]: true
    };

    if (position) {
      state = {
        ...state,
        position
      };
    }
    this.setState(state);
  }

  onHide(name) {
    this.setState({
      [`${name}`]: false
    });
  }

  render() {
    return (
      <div className="dialog-demo">
        <div className="card">
          <Button
            label="Solicita este servicio."
            icon="pi pi-external-link"
            onClick={() => this.onClick("displayResponsive")}
          />
          <Dialog
            header="Solicita este Servicio"
            visible={this.state.displayResponsive}
            onHide={() => this.onHide("displayResponsive")}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "81vw" }}
          >
            <div className="popup__contacto">
              <ContactoComponent/>
            </div>  
          </Dialog>
        </div>
      </div>
    );
  }
}

export default PopUpComponent;
