import React, { Component } from 'react';
import reactDom from 'react-dom';
import { NavbarItems } from "./NavbarItems";
import './Navbar.css';
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

class Navbar extends Component{
    state ={ clicked: false }

    handleClick = () => {
        this.setState({ clicked: !this.state.clicked})
    }

    render(){
        return(
            <nav className="NavbarItems">
                {/*<h1 className="navbar-logo"></h1>*/}
                <a href="./#/"><img src='img/logoRoedjot.png'></img></a>
                <div className="menu-icon" onClick={this.handleClick}>
                    <i className={this.state.clicked ? 'pi pi-fw pi-times':'pi pi-fw pi-bars'}></i>
                </div>
                <ul className={this.state.clicked ? 'nav-menu active': 'nav-menu'}>
                    {NavbarItems.map((item, index) => {
                        return (
                            <li key={index}>
                                <a className={item.cName} href={item.url}>
                                    {item.title}
                                </a>
                            </li>
                        )
                    })}
                </ul>
            </nav>
        )
    }
}

export default Navbar;