import React, { Component } from 'react';
import Navbar from '../common/navbarComponent/Navbar';
import Footer from '../common/footerComponent/Footer';
import { Card } from 'react-bootstrap';
import WhatsAppWidget from 'react-whatsapp-widget';
import MessengerCustomerChat from 'react-messenger-customer-chat';

import './servicios.css';

class ServiciosViewPresentation extends Component{
    render(){
        return(
            <div>
                <Navbar />
                    <div className="Servicio">
                        <Card className="Servicio__uno animate__animated animate__backInLeft">
                            <Card.Body className="servicio__cuerpouno">
                                <Card.Title className="cuerpo__titulo">
                                    Servicio de Desinsectación
                                </Card.Title>
                                <Card.Text className="cuerpo__description">
                                    Control de insectos rastreros y voladores que forman parte de la familia de antropodos los cuales pueden ser nosivos para la salud humana, aplicando metodos químicos como la nebulización espacial y asperción.
                                </Card.Text>
                                <a href="./#/servicios/desinsectacion" className="cuerpo__enlace">Leer Más</a>
                            </Card.Body>
                            <div className="cuerpo__imagen">
                                <Card.Img  className="cuerpo__imagen--serv" variant="top" src='img/servicio/desinsectacion.jpg' onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}/>
                            </div>
                        </Card>

                        <Card className="Servicio__uno animate__animated animate__backInLeft">
                            <Card.Body className="servicio__cuerpouno">
                                <Card.Title className="cuerpo__titulo">
                                    Servicio de Desratización
                                </Card.Title>
                                <Card.Text className="cuerpo__description">
                                    Colocación de estaciones de cebadero como primer anillo de seguridad frente a los roedores usando un potente rodenticida para combatir situaciones complejas de epidemias y daños de activos en las empresas realizandolo de manera preventiva y correctiva.
                                </Card.Text>
                                <a href="./#/servicios/desratizacion" className="cuerpo__enlace">Leer Más</a>
                            </Card.Body>
                            <div className="cuerpo__imagen">
                                <Card.Img  className="cuerpo__imagen--serv" variant="top" src='img/servicio/desratizacion.jpg' onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}/>
                            </div>
                        </Card>

                        <Card className="Servicio__dos animate__animated animate__backInRight">
                            <div className="cuerpo__imagen">
                                <Card.Img  className="cuerpo__imagen--serv" variant="top" src='img/servicio/desinfeccion.jpg' onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}/>  
                            </div>
                            <Card.Body className="servicio__cuerpouno">
                                <Card.Title className="cuerpo__titulo">
                                    Servicio de Desinfección
                                </Card.Title>
                                <Card.Text className="cuerpo__description">
                                    Proceso químico de Neutralización y erradicación de los microorganismos sin discriminación (Tales como agentes patógenos) al igual como bacterias, virus y protozoos impidiendo el crecimiento de microorganismos patógenos en fase vegetativa que se encuentren en objetos inertes. Los desinfectantes reducen los organismos nocivos a un nivel que no dañan la salud ni la calidad de los bienes perecederos.
                                </Card.Text>
                                <a href="./#/servicios/desinfeccion" className="cuerpo__enlace">Leer Más</a>
                            </Card.Body>
                        </Card>

                        <Card className="Servicio__dos animate__animated animate__backInRight">
                            <div className="cuerpo__imagen">
                                <Card.Img  className="cuerpo__imagen--serv" variant="top" src='img/servicio/limpieza-de-ambientes.jpg' onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}/>
                            </div>
                            <Card.Body className="servicio__cuerpouno">
                                <Card.Title className="cuerpo__titulo">
                                    Limpieza de Ambientes
                                </Card.Title>
                                <Card.Text className="cuerpo__description">
                                    Evitar el contagio de enfermedades o alergias y para tener espacios más cómodos para vivir controlando la presencia de bacterias, parásitos y virus que son contaminantes, como así también cualquier tipo de suciedad o basura que son elementos dañinos para la salud de la persona.
                                </Card.Text>
                                <a href="./#/servicios/limpiezaAreas" className="cuerpo__enlace">Leer Más</a>
                            </Card.Body>
                        </Card>

                        <Card className="Servicio__uno animate__animated animate__backInLeft">
                            <Card.Body className="servicio__cuerpouno">
                                <Card.Title className="cuerpo__titulo">
                                    Limpieza y desinfección de Reservorios de Agua
                                </Card.Title>
                                <Card.Text className="cuerpo__description">
                                    Contrastar la prolideración de organismos nocivos y bacterias que puedan atentar la salud mediante la acumulación de barro, tierra y particulas generados con el paso del tiempo. Por ello la limpieza y desinfección de los reservorios en establecimientos comerciales, industriales y de servicios, así como los de las viviendas multifamiliares, deberian ejercutarse casa seis(6) meses.
                                </Card.Text>
                                <a href="./#/servicios/manttoReservorios" className="cuerpo__enlace">Leer Más</a>
                            </Card.Body>
                            <div className="cuerpo__imagen">
                                <Card.Img  className="cuerpo__imagen--serv" variant="top" src='img/servicio/limpieza-reservorios-de-agua.jpg' onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}/>
                            </div>
                        </Card>

                        <Card className="Servicio__uno animate__animated animate__backInLeft">
                            <Card.Body className="servicio__cuerpouno">
                                <Card.Title className="cuerpo__titulo">
                                    Control de Aves Urbanas
                                </Card.Title>
                                <Card.Text className="cuerpo__description">
                                    Evitar la alta densidad poblacional y amenaza que representan para la salud pública, puesto que trae diversos problemas como transmisión de enfermedades(Cryptococcus neoformans, Chlamydophila psittaci e Histoplasma capsulatum), contaminación ambiental(ya que el excremento de paloma puede provocar repelencia por su aspecto y olor, además de que deja manchas), deterioro de infraestructura (sus excrementos contienen ácidos que reaccionan con la piedra, produciendo efectos corrosivos y creando además problemas de naturaleza estética e higiénica).
                                </Card.Text>
                                <a href="./#/servicios/controlAves" className="cuerpo__enlace">Leer Más</a>
                            </Card.Body>
                            <div className="cuerpo__imagen">
                                <Card.Img  className="cuerpo__imagen--serv" variant="top" src='img/servicio/control-de-aves.jpg' onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}/>
                            </div>      
                        </Card>

                        <Card className="Servicio__dos animate__animated animate__backInRight">
                            <div className="cuerpo__imagen">
                                <Card.Img  className="cuerpo__imagen--serv" variant="top" src='img/servicio/limpieza-campanas-extractoras.jpg' onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}/>
                            </div>
                            <Card.Body className="servicio__cuerpouno">
                                <Card.Title className="cuerpo__titulo">
                                    Limpieza de Campana Extractora de grasa
                                </Card.Title>
                                <Card.Text className="cuerpo__description">
                                    Realizar un mantenimiento, limpieza y desengrase correcto de estos para evitar así el riesgo de sufrir graves accidentes, daños que pueden llegar a ser irreversibles en las instalaciones. De esta manera cumplir con las normas de prevención de incendios, evitar riesgos de contaminación alimentaria, alargar la vida útil de la instalación y los equipos, incrementar el rendimiento del sistema de extracción.
                                </Card.Text>
                                <a href="./#/servicios/manttoCampana" className="cuerpo__enlace">Leer Más</a>
                            </Card.Body>
                        </Card>

                        <Card className="Servicio__dos animate__animated animate__backInRight">
                            <div className="cuerpo__imagen">
                                <Card.Img  className="cuerpo__imagen--serv" variant="top" src='img/servicio/limpieza-de-trampa-de-grasa.jpg' onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}/>
                            </div>
                            <Card.Body className="servicio__cuerpouno">
                                <Card.Title className="cuerpo__titulo">
                                    Limpieza de Trampa de Grasa
                                </Card.Title>
                                <Card.Text className="cuerpo__description">
                                    Mantener una limpieza regular de trampas de grasa para evitar molestas obstrucciones o malos olores en el sistema de cañería, así tambien la formación de focos de patógenos generados por la descomposición de los restos de alimentos, capaces de contaminar tanto el exterior como el mismo negocio. Por ello esas sustancias que se acumulan, necesitan ser evacuadas con regularidad por gestores profesionales de residuos, debidamente protegidos, debido a su riesgo de contacto con patógenos perjudiciales para la salud.
                                </Card.Text>
                                <a href="./#/servicios/manttoTrampa" className="cuerpo__enlace">Leer Más</a>
                            </Card.Body>
                        </Card>
                    </div>
                <Footer />

                <div className="widget-fb">
                <MessengerCustomerChat
                pageId="1853691534879265"
                appId="293907205561035"
                language= 'es_ES'
                greeting_dialog_display ="fade"
                greeting_dialog_delay = "2"
                />
                </div>

                <div className="widget-wsp">
                    <WhatsAppWidget 
                    phoneNumber='51973667643' 
                    companyName='Roedjot S.A.C' 
                    textReplyTime='Tipicamente responde a 2 minutos' 
                    message='¡Hola! 👋🏼 ¿Qué podemos hacer por usted?' 
                    sendButton='Enviar' 
                    placeholder='Escribe tu mensaje' 
                    />
                </div>
            </div>
        )
    }
}

export default ServiciosViewPresentation;