
export const CardsItems = [
    {
        id: 1,
        title: 'desinsectación',
        image: 'img/inicio/card-servicio1.jpg',
        content: 'Eliminación de todo tipo de plagas de manera rápida, segura y efectiva.',
        url: './#/servicios/desinsectacion'
    },
    {
        id: 2,
        title: 'desratización',
        image: 'img/inicio/card-servicio2.jpg',
        content:'Elimine enfermedades de la peste bubónica, rabia, cólera adecuadamente',
        url: './#/servicios/desratizacion'
    },
    {
        id: 3,
        title: 'desinfección de ambientes',
        image: 'img/inicio/card-servicio3.jpg',
        content:'Elimine bacterias, vírus, hongos y microorganismos.',
        url: './#/servicios/desinfeccion'
    },
    {
        id: 4,
        title: 'limpieza de ambientes',
        image: 'img/inicio/card-servicio4.jpg',
        content:'Limpieza para tratamientos profundos contra la suciedad.',
        url: './#/servicios/limpiezaAreas'
    },
    {
        id: 5,
        title: 'limpieza y desinfección reservorios de agua',
        image: 'img/inicio/card-servicio5.jpg',
        content:'Eliminación de suciedad y los microorganismos de la superficie.',
        url: './#/servicios/manttoReservorios'
    },
    {
        id: 7,
        title: 'control de aves urbanas',
        image: 'img/inicio/card-servicio7.jpg',
        content:'Evite el anidamineto de aves (palomas, gorriones, etc), evite plagas de insectos, ácaros y arriesgar su salud.',
        url: './#/servicios/controlAves'
    },
    {
        id: 8,
        title: 'limpieza de campana extractora',
        image: 'img/inicio/card-servicio8.jpg',
        content:'Realice la limpieza de grasa, humo y suciedad acumulada en los ductos y motor en su extractor.',
        url: './#/servicios/manttoCampana'
    },
    {
        id: 9,
        title: 'limpieza de trampa de grasa',
        image: 'img/inicio/card-servicio9.jpg',
        content:'Realice un adecuado mantenimiento de la trampa de grasa para evitar problemas y multas.',
        url: './#/servicios/manttoTrampa'
    },
]