
export const PersonalItems = [
    {
        id:1,
        name: 'Edson Rojas Peña',
        image: 'img/nosotros/personal2.png',
        cargo: 'Jefe de Operaciones',
        descripcion:'Contador Público Colegiado, diplomado en Dirección de UTS, miembro de la brigada de intervención de emergencias y desastres del MINSA. Miembro del equipo de nebulizadores Elite del MINSA, Gerente General Corporación Industrial Roedjot - Gerente General del Roalco y Asociados.'
    },
    {
        id:2,
        name: 'Juan Rojas Zafra',
        image: 'img/nosotros/personal3.png',
        cargo: 'Jefe de Control de Calidad',
        descripcion:'Especialista en Salud Publica con mas de 35 años de experiencia como inspector sanitario del MINSA. Miembro Supervisor del equipo elite de Nebulizadores del Minsa. Brigadista del MINSA.'
    },
    {
        id:3,
        name: 'Pamela Mascaraqui Berrospi',
        image: 'img/nosotros/personal4.png',
        cargo: 'Directora Técnica',
        descripcion:' Ingeniera Industrial CIP 213523, con conocimientos de Sistema Integrados de Gestión SIG 9001, 14001, 45001; Auditoria de Sistemas integrados de Gestion, Auditoria interna según OHSAS 18001, Gerencia y Gestión Integral de la Seguridad y Salud ocupacional.'
    },
]