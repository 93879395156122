import React, { Component } from 'react';
import Navbar  from '../common/navbarComponent/Navbar';
import Footer from '../common/footerComponent/Footer';
import WhatsAppWidget from 'react-whatsapp-widget';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { Card } from 'react-bootstrap';
import PopUpComponent from '../common/popUpContacComponent/PopUpComponent';
import GalleriaComponent from '../common/galleriaComponent/GalleriaComponent';
import { GalleriaItems_limpiezaAmbientes } from '../common/galleriaComponent/Galerias';

import './limpiezaAreas.css';

class LimpiezaAreasViewPresentation extends Component{
    render(){
        return(
            <div>
                <Navbar />
                <div>
                    <img className="banner_servicio" src='img/limpiezaAreas/limpieza-de-ambientes-roedjot.jpg' alt="Servicio de limpieza de ambientes en Lima"></img>   
                </div>
                <div className="tittle animate__animated animate__slideInUp">
                    <h2>LIMPIEZA DE <span>ÁREAS</span></h2>
                </div>

                <Card className="limpiezaAreas animate__animated animate__slideInUp">
                            <Card.Body className="limpiezaAreas__info">
                                <Card.Text className="limpiezaAreas__info--cuerpo">
                                    <p>Contamos con equipos modernos y personal especializado para la limpieza de todo tipo de establecimiento, residencial, comercial, servicios e industriales, garantizando ambientes seguros, libres de polvo y desperdicios, así como reducir o eliminar la presencia de todo tipo de microorganismos.</p>

                                    <p>Las técnicas de limpieza se encuentran sujetas a un extenso desarrollo; éstas tratan de poner a punto detergentes químicos cada vez más eficientes, lo que ha hecho más complicada la elección de los químicos más apropiados, por lo que el personal responsable de la limpieza necesita un conocimiento más apropiado al respecto.</p>

                                    <Card.Title className="mb-2 text-muted">OBJETIVO DE LA LIMPIEZA:</Card.Title>
                                    <ul>
                                        <li>Reducir el número de microorganismos que puedan entrar en contacto con los clientes o el personal</li>
                                        <li>Reducir el riesgo de accidentes evitando caídas.</li>
                                        <li>Brindar un entorno agradable.</li>
                                    </ul>
                                </Card.Text>
                            </Card.Body>
                    </Card>
                <PopUpComponent/>
                <GalleriaComponent body={GalleriaItems_limpiezaAmbientes} />
                <Footer />
                <div className="widget-fb">
                    <MessengerCustomerChat
                    pageId="1853691534879265"
                    appId="293907205561035"
                    language= 'es_ES'
                    greeting_dialog_display ="fade"
                    greeting_dialog_delay = "2"
                />
                </div>

                <div className="widget-wsp">
                    <WhatsAppWidget 
                    phoneNumber='51973667643' 
                    companyName='Roedjot S.A.C' 
                    textReplyTime='Tipicamente responde a 2 minutos' 
                    message='¡Hola! 👋🏼 ¿Qué podemos hacer por usted?' 
                    sendButton='Enviar' 
                    placeholder='Escribe tu mensaje' 
                    />
                </div>
            </div>
            
        )
    }
}
export default LimpiezaAreasViewPresentation;