import React, { Component } from 'react';
import Navbar  from '../common/navbarComponent/Navbar';
import Footer from '../common/footerComponent/Footer';
import WhatsAppWidget from 'react-whatsapp-widget';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { Card } from 'react-bootstrap';
import PopUpComponent from '../common/popUpContacComponent/PopUpComponent';

import './manttoCampana.css';
import GalleriaComponent from '../common/galleriaComponent/GalleriaComponent';
import { GalleriaItems_limpiezaCampanas } from '../common/galleriaComponent/Galerias';
//import video1 from './media/limpieza-campana-roedjot.mp4';


class ManttoCampanaViewPresentation extends Component{
    render(){
        return(
            <div>
                <Navbar />
                <div>
                    <img className="banner_servicio" src='img/limpiezaCampanas/limpieza-de-campanas-extractoras.jpg' alt="Servicio Limpieza de campanas extractoras en Lima"></img>
                </div>
                <div className="tittle animate__animated animate__slideInUp">
                    <h2>LIMPIEZA DE <span>CAMPANAS EXTRACTORAS</span></h2>
                </div>

                <Card className="manttoCampana animate__animated animate__slideInUp">
                    <Card.Body className="manttoCampana__info">
                        <Card.Text className="manttoCampana__info--cuerpo">
                            <p>La reputación de Saneamiento Roedjot S.A.C. se basa en la limpieza de todo el sistema de escape – desde el ventilador de techo en la línea de equipamiento. No nos limitamos a limpiar su sistema; lo documentamos y proporcionamos imágenes y retroalimentación para asegurar la eficiencia de su sistema. Si su sistema de escape entero no está siendo limpiado, la grasa restante es un peligro de incendio que podría costarle su negocio, causar lesiones o peor.</p>
                        </Card.Text>
                        <Card.Title className="mb-2 text-muted">DESCRIPCIÓN DEL SERVICIO:</Card.Title>
                        <Card.Text className="manttoCampana__info--cuerpo">
                            <ul>
                                <li>Desmontaje del ducto de entrada de la campana extractora.</li>
                                <li>Limpieza externa del motor de extracción.</li>
                                <li>Limpieza y desengrasado de campanas de desfogue de humo al 100%.</li>
                                <li>Desmontaje y limpieza de filtros de polvo y grasa.</li>
                                <li>Para la limpieza profunda se aplicarán en todas las superficies, externas e internas, los diluyentes específicos para la eliminación de grasas y suciedades adheridas.</li>
                                <li>La remoción de grasa se realizará con la ayuda de una máquina de alta presión de vapor de agua a 120°C (OPCIONAL).</li>
                                <li>Se enjuagará las áreas desengrasadas con agua y detergente.</li>
                            </ul>
                        </Card.Text>
                    </Card.Body>
                </Card>
                <PopUpComponent/>
                    <GalleriaComponent body={GalleriaItems_limpiezaCampanas} />    
                <Footer />

                <div className="widget-fb">
                    <MessengerCustomerChat
                    pageId="1853691534879265"
                    appId="293907205561035"
                    language= 'es_ES'
                    greeting_dialog_display ="fade"
                    greeting_dialog_delay = "2"
                />
                </div>

                <div className="widget-wsp">
                    <WhatsAppWidget 
                    phoneNumber='51973667643' 
                    companyName='Roedjot S.A.C' 
                    textReplyTime='Tipicamente responde a 2 minutos' 
                    message='¡Hola! 👋🏼 ¿Qué podemos hacer por usted?' 
                    sendButton='Enviar' 
                    placeholder='Escribe tu mensaje' 
                    />
                </div>
            </div>    
        )
    }
}
export default ManttoCampanaViewPresentation;