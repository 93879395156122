import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { ManttoReservoriosViewPresentation } from '../manttoReservoriosComponent';

class ManttoReservoriosViewComponent extends Component {
    render(){
        return(
            <div className={this.props.isLoading ? "card card-with-opacity" : "card"}>
            <ManttoReservoriosViewPresentation />
            </div>
        )
    }
}
export default ManttoReservoriosViewComponent;
