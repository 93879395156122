import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { NosotrosViewPresentation } from "../nosotrosComponent";

class NosotrosViewContainer extends Component {


render() {
    return (
        <div className={this.props.isLoading ? "card card-with-opacity" : "card"}>
        <NosotrosViewPresentation/>
    </div>
    );
    }
}

export default NosotrosViewContainer;

