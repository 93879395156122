import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { ServiciosViewPresentation } from '../serviciosComponent';

class ServiciosViewContainer extends Component{
    render(){
        return(
            <div className={this.props.isLoading ? "card card-with-opacity" : "card"}>
            <ServiciosViewPresentation/>
            </div>
        )
    }
}

export default ServiciosViewContainer;