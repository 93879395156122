

export const NavbarItems = [
    {
        title: 'Inicio',
        url: './#/',
        cName: 'nav-links'
    },
    {
        title: 'Nosotros',
        url: './#/nosotros',
        cName: 'nav-links'
    },
    {
        title: 'Servicios',
        url: './#/servicios',
        cName: 'nav-links'
    },
    {
        title: 'Contacto',
        url: './#/contacto',
        cName: 'nav-links'
        /*url: 'http://'+location.hostname+':3002'+'/#/contacto',*/
    }    
]